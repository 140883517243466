//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-376:_9876,_4248,_4508,_3916,_9648,_4800,_2928,_1328;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-376')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-376', "_9876,_4248,_4508,_3916,_9648,_4800,_2928,_1328");
        }
      }catch (ex) {
        console.error(ex);
      }